import {
  ADMIN_PROFILE_TABS,
  DEALER_PROFILE_TABS,
  getConsumerProfileTabs,
  SALES_PROFILE_TABS,
} from 'data/constants';
import { Permission, User } from 'shared/types/models';
import { hasPermission } from 'shared/utils/functions';

type GetTabsArgs = {
  user: User;
};
export const getTabs = ({ user }: GetTabsArgs) => {
  if (hasPermission(user, Permission.dealer)) {
    return DEALER_PROFILE_TABS;
  }

  if (hasPermission(user, Permission.user)) {
    return getConsumerProfileTabs();
  }

  if (hasPermission(user, Permission.sales)) {
    return SALES_PROFILE_TABS;
  }

  if (hasPermission(user, Permission.admin)) {
    return ADMIN_PROFILE_TABS;
  }

  return [];
};
